import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <h1>Contact</h1>

    <form action="https://formspree.io/mlenqbag" method="POST">
      <div class="row">
        <div class="col-25">
          <label for="replyto">Your email:</label>
        </div>
        <div class="col-75">
          <input id="replyto" type="text" name="_replyto" />
        </div>
      </div>
      <div class="row">
        <div class="col-25">
          <label for="message">Your message:</label>
        </div>
        <div class="col-75">
          <textarea
            name="message"
            id="message"
            style={{ height: "200px" }}
          ></textarea>
        </div>
      </div>

      <button type="submit">Send</button>
    </form>
  </Layout>
)

export default ContactPage
